import React from "react";
import LoadingSpinner from "./LoadingSpinner";

interface LoadingStateProps {
  isLoading: boolean;
  children: React.ReactNode;
  fullScreen?: boolean;
}

export default function LoadingState({
  isLoading,
  children,
  fullScreen = false,
}: LoadingStateProps) {
  if (!isLoading) return <>{children}</>;

  const containerClasses = fullScreen
    ? "fixed inset-0 bg-white/80 backdrop-blur-sm z-50"
    : "relative min-h-[200px]";

  return (
    <div className={containerClasses}>
      <div className="absolute inset-0 flex items-center justify-center">
        <LoadingSpinner size={fullScreen ? "large" : "medium"} />
      </div>
    </div>
  );
}
