export const translations = {
  ro: {
    nav: {
      home: "Acasă",
      services: "Servicii",
      about: "Despre noi",
      fleet: "Flotă",
      contact: "Contact",
      events: "Evenimente",
    },
    hero: {
      company: "ARBOTRANS",
      title: "Soluții logistice complete pentru afacerea ta",
      subtitle: "Transport rutier de mărfuri intern și internațional",
      cta: "Contactează-ne",
      motto: "Sigur și rapid",
      services: "Serviciile Noastre",
      viewFleet: "Vezi Flota",
    },
    fleet: {
      title: "Flotă",
      trucks: "Camioane",
      constructionEquipment: "Utilaje de Construcții",
      passengerTransport: "Transport de Persoane",
      imageAlt: "poză",
    },
    arboland: {
      title: "ARBOLAND EVENTS",
      description:
        "Sala noastră de evenimente oferă spațiul perfect pentru orice ocazie specială. Cu facilități moderne și un ambient deosebit, ARBOLAND EVENTS este alegerea ideală pentru evenimentul dumneavoastră.",
      imageAlt: "poză",
    },
    services: {
      title: "Serviciile Noastre",
      subtitle:
        "Soluții logistice profesionale adaptate nevoilor afacerii tale",
      viewPhotos: "Vezi Fotografii",
      list: [
        "Transport rutier de agregate cu autobasculante (toată gama)",
        "Prestări servicii cu utilaje de construcții (toată gama)",
        "Prestări servicii cu autobetoniere și pompă de beton",
        "Vânzări agregate de carieră și balastieră",
        "Transport rutier de persoane intern și internațional",
        "Transport rutier de mărfuri intern și internațional",
        "Service auto autorizat RAR. LKW DAFROTI SERVICE",
        "Sală de evenimente - ARBOLAND EVENTS",
      ],
    },
    about: {
      title: "Despre Noi",
      description:
        "Cu o experiență vastă în domeniul transporturilor și logisticii, ARBOTRANS oferă servicii complete și profesionale pentru toate nevoile dumneavoastră. Ne mândrim cu flota noastră modernă și personalul nostru calificat.",
      values: {
        title: "Valorile Noastre",
        list: ["Profesionalism", "Punctualitate", "Siguranță", "Inovație"],
      },
    },
    contact: {
      title: "Contact",
      address: "Adresa noastră",
      phone: "Telefon",
      email: "Email",
      companyDetails: "Detalii Companie",
      headquarters: "Sediul Central",
      workingPoint: "Punct de lucru",
      developedBy: "Dezvoltat de",
    },
    events: {
      title: "ARBOLAND EVENTS",
      description:
        "Sala noastră de evenimente oferă spațiul perfect pentru orice ocazie specială. Cu facilități moderne și un ambient deosebit, ARBOLAND EVENTS este alegerea ideală pentru evenimentul dumneavoastră.",
    },
  },
  en: {
    nav: {
      home: "Home",
      services: "Services",
      about: "About",
      fleet: "Fleet",
      contact: "Contact",
      events: "Events",
    },
    hero: {
      company: "ARBOTRANS",
      title: "Complete logistics solutions for your business",
      subtitle: "Road transport of goods domestic and international",
      cta: "Contact us",
      motto: "Safe & Faster",
      services: "Our Services",
      viewFleet: "View Fleet",
    },
    fleet: {
      title: "Fleet",
      trucks: "Trucks",
      constructionEquipment: "Construction Equipment",
      passengerTransport: "Passenger Transport",
      imageAlt: "image",
    },
    arboland: {
      title: "ARBOLAND EVENTS",
      description:
        "Our event venue provides the perfect space for any special occasion. With modern facilities and an exceptional ambiance, ARBOLAND EVENTS is the ideal choice for your event.",
      imageAlt: "image",
    },
    services: {
      title: "Our Services",
      subtitle:
        "Professional logistics solutions tailored to your business needs",
      viewPhotos: "View Photos",
      list: [
        "Road transport of aggregates with dump trucks (full range)",
        "Construction equipment services (full range)",
        "Concrete mixer and concrete pump services",
        "Quarry and ballast aggregates sales",
        "Domestic and international passenger transport",
        "Domestic and international freight transport",
        "RAR authorized auto service. LKW DAFROTI SERVICE",
        "Event venue - ARBOLAND EVENTS",
      ],
    },
    about: {
      title: "About Us",
      description:
        "With extensive experience in transportation and logistics, ARBOTRANS provides comprehensive and professional services for all your needs. We take pride in our modern fleet and qualified personnel.",
      values: {
        title: "Our Values",
        list: ["Professionalism", "Punctuality", "Safety", "Innovation"],
      },
    },
    contact: {
      title: "Contact",
      address: "Our Address",
      phone: "Phone",
      email: "Email",
      companyDetails: "Company Details",
      headquarters: "Headquarters",
      workingPoint: "Working Point",
      developedBy: "Developed by",
    },
    events: {
      title: "ARBOLAND EVENTS",
      description:
        "Our event venue provides the perfect space for any special occasion. With modern facilities and an exceptional ambiance, ARBOLAND EVENTS is the ideal choice for your event.",
    },
  },
  de: {
    nav: {
      home: "Startseite",
      services: "Dienstleistungen",
      about: "Über uns",
      fleet: "Flotte",
      contact: "Kontakt",
      events: "Veranstaltungen",
    },
    hero: {
      company: "ARBOTRANS",
      title: "Komplette Logistiklösungen für Ihr Unternehmen",
      subtitle: "Straßentransport von Gütern im In- und Ausland",
      cta: "Kontaktieren Sie uns",
      motto: "Sicher & Schnell",
      services: "Unsere Dienstleistungen",
      viewFleet: "Flotte ansehen",
    },
    fleet: {
      title: "Flotte",
      trucks: "LKW",
      constructionEquipment: "Baumaschinen",
      passengerTransport: "Personentransport",
      imageAlt: "Bild",
    },
    arboland: {
      title: "ARBOLAND EVENTS",
      description:
        "Unser Veranstaltungsort bietet den perfekten Raum für jeden besonderen Anlass. Mit modernen Einrichtungen und einer außergewöhnlichen Atmosphäre ist ARBOLAND EVENTS die ideale Wahl für Ihre Veranstaltung.",
      imageAlt: "Bild",
    },
    services: {
      title: "Unsere Dienstleistungen",
      subtitle:
        "Professionelle Logistiklösungen, maßgeschneidert auf die Bedürfnisse Ihres Unternehmens",
      viewPhotos: "Fotos ansehen",
      list: [
        "Straßentransport von Schüttgut mit Kipplastern (komplettes Sortiment)",
        "Baumaschinendienstleistungen (komplettes Sortiment)",
        "Betonmischer- und Betonpumpendienste",
        "Verkauf von Steinbruch- und Kiesgrubenaggregate",
        "Nationaler und internationaler Personentransport",
        "Nationaler und internationaler Gütertransport",
        "RAR-autorisierter Autoservice. LKW DAFROTI SERVICE",
        "Veranstaltungsort - ARBOLAND EVENTS",
      ],
    },
    about: {
      title: "Über Uns",
      description:
        "Mit umfangreicher Erfahrung in Transport und Logistik bietet ARBOTRANS umfassende und professionelle Dienstleistungen für alle Ihre Bedürfnisse. Wir sind stolz auf unsere moderne Flotte und unser qualifiziertes Personal.",
      values: {
        title: "Unsere Werte",
        list: ["Professionalität", "Pünktlichkeit", "Sicherheit", "Innovation"],
      },
    },
    contact: {
      title: "Kontakt",
      address: "Unsere Adresse",
      phone: "Telefon",
      email: "E-Mail",
      companyDetails: "Firmendetails",
      headquarters: "Hauptsitz",
      workingPoint: "Arbeitspunkt",
      developedBy: "Entwickelt von",
    },
    events: {
      title: "ARBOLAND EVENTS",
      description:
        "Unser Veranstaltungsort bietet den perfekten Raum für jeden besonderen Anlass. Mit modernen Einrichtungen und einer außergewöhnlichen Atmosphäre ist ARBOLAND EVENTS die ideale Wahl für Ihre Veranstaltung.",
    },
  },
};
