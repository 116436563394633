export const photos: { [key: string]: string } = {
  arboland:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/arboland.png",
  logo: "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/logo.jpg",
  poza1:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-1.jpeg",
  poza2:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-2.jpeg",
  poza3:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-3.jpeg",
  poza4:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-4.jpeg",
  poza5:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-5.jpeg",
  poza6:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-6.jpeg",
  poza7:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-7.jpeg",
  poza8:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-8.jpeg",
  poza9:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-9.jpeg",
  poza10:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-10.jpeg",
  poza11:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-11.jpeg",
  poza12:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-12.jpeg",
  poza13:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-13.jpeg",
  poza14:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-14.jpeg",
  poza15:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-15.jpeg",
  poza16:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-16.jpeg",
  poza17:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-17.jpeg",
  poza18:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-18.jpeg",
  poza19:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-19.jpeg",
  poza20:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-20.jpeg",
  poza21:
    "https://raw.githubusercontent.com/petrutaraul/arbotrans-photos/refs/heads/main/poza-21.jpeg",
};

export const arbotransPhotos = Object.entries(photos)
  .filter(([key]) => key.startsWith("poza"))
  .map(([_, value]) => value);
