import React from "react";
import { useLanguage } from "../context/LanguageContext";
import { translations } from "../data/translations";

interface SEOProps {
  path: string;
}

export default function SEO({ path }: SEOProps) {
  const { language } = useLanguage();
  const t = translations[language];

  const metaData = {
    "/": {
      title: `${t.hero.company} - ${t.hero.title}`,
      description: t.hero.subtitle,
      keywords: "transport, logistics, shipping, cargo, romania",
      image: "https://arbotrans.ro/og-image.jpg",
    },
    "/fleet": {
      title: `${t.fleet.title} - ${t.hero.company}`,
      description: t.fleet.title,
      keywords: "fleet, vehicles, trucks, trailers, transport, logistics",
      image: "https://arbotrans.ro/og-image.jpg",
    },
    "/arboland": {
      title: `${t.arboland.title} - ${t.hero.company}`,
      description: t.arboland.title,
      keywords: "arboland, forest, wood, trees, nature, environment",
      image: "https://arbotrans.ro/og-image.jpg",
    },
  };

  const currentMeta = metaData[path as keyof typeof metaData] || metaData["/"];
  const canonicalUrl = `https://arbotrans.ro${
    language === "ro" ? "" : `/${language}`
  }${path}`;

  React.useEffect(() => {
    document.title = currentMeta.title;

    const metaTags = {
      description: currentMeta.description,
      keywords: currentMeta.keywords,
      "og:title": currentMeta.title,
      "og:description": currentMeta.description,
      "og:image": currentMeta.image,
      "og:url": canonicalUrl,
      "twitter:title": currentMeta.title,
      "twitter:description": currentMeta.description,
      "twitter:image": currentMeta.image,
    };

    Object.entries(metaTags).forEach(([name, content]) => {
      const selector = name.startsWith("og:")
        ? `meta[property="${name}"]`
        : `meta[name="${name}"]`;

      document.querySelector(selector)?.setAttribute("content", content);
    });

    // Update canonical link
    document
      .querySelector('link[rel="canonical"]')
      ?.setAttribute("href", canonicalUrl);
  }, [path, language]);

  return null;
}
