import { lazy, Suspense } from "react";
import LoadingState from "../components/LoadingState";

const Hero = lazy(() => import("../components/Hero"));
const Services = lazy(() => import("../components/Services"));
const About = lazy(() => import("../components/About"));
const Events = lazy(() => import("../components/Events"));
const Contact = lazy(() => import("../components/Contact"));
const Fleet = lazy(() => import("../components/Fleet"));
const Arboland = lazy(() => import("../components/Arboland"));

const LoadingFallback = () => (
  <LoadingState isLoading={true} fullScreen>
    <div />
  </LoadingState>
);

const HomePage = (
  <Suspense fallback={<LoadingFallback />}>
    <Hero />
    <Suspense
      fallback={
        <LoadingState isLoading={true} fullScreen={false}>
          <div className="min-h-screen" />{" "}
        </LoadingState>
      }
    >
      <Services />
      <About />
      <Events />
      <Contact />
    </Suspense>
  </Suspense>
);

export const routes = {
  "/fleet": (
    <Suspense fallback={<LoadingFallback />}>
      <Fleet />
    </Suspense>
  ),
  "/arboland": (
    <Suspense fallback={<LoadingFallback />}>
      <Arboland />
    </Suspense>
  ),
  "/": HomePage,
};
