import React from "react";
import { photos } from "../data/photos";

interface LoadingSpinnerProps {
  size?: "small" | "medium" | "large";
  showLogo?: boolean;
}

export default function LoadingSpinner({
  size = "medium",
  showLogo = true,
}: LoadingSpinnerProps) {
  const sizeClasses = {
    small: "w-16 h-16",
    medium: "w-24 h-24",
    large: "w-32 h-32",
  };

  const logoSizes = {
    small: "w-8",
    medium: "w-12",
    large: "w-16",
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        {/* Outer spinning ring */}
        <div
          className={`
            ${sizeClasses[size]}
            border-4 
            border-primary/20 
            border-t-primary 
            rounded-full 
            animate-spin
          `}
        />

        {/* Center logo */}
        {showLogo && (
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src={photos.logo}
              alt="ARBOTRANS"
              className={`
                ${logoSizes[size]}
                h-auto
                object-contain
                animate-pulse
              `}
            />
          </div>
        )}
      </div>
    </div>
  );
}
