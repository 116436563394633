import React from "react";
import { LanguageProvider } from "./context/LanguageContext";
import Navbar from "./components/Navbar";
import { routes } from "./lib/routes";
import SEO from "./lib/SEO";

function App() {
  const path = window.location.pathname;

  return (
    <LanguageProvider>
      <SEO path={path} />
      <div className="min-h-screen">
        <Navbar />
        {(routes as { [key: string]: JSX.Element })[path] || routes["/"]}
      </div>
    </LanguageProvider>
  );
}

export default App;
