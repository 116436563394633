import React, { useEffect, useRef } from "react";
import { Globe, Menu, X } from "lucide-react";
import { useLanguage } from "../context/LanguageContext";
import { translations } from "../data/translations";
import { photos } from "../data/photos";

export default function Navbar() {
  const { language, setLanguage } = useLanguage();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = React.useState(false);
  const langMenuRef = useRef<HTMLDivElement>(null);
  const t = translations[language];

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        langMenuRef.current &&
        !langMenuRef.current.contains(event.target as Node)
      ) {
        setIsLangMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLanguageChange = (lang: "ro" | "en" | "de") => {
    setLanguage(lang);
    setIsLangMenuOpen(false);
  };

  const handleSectionClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    sectionId: string
  ) => {
    e.preventDefault();
    const path = window.location.pathname;

    if (path === "/fleet") {
      window.location.href = `/#${sectionId}`;
    } else {
      document
        .getElementById(sectionId)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <a className="text-2xl font-bold text-primary" href="/">
              <img
                src={photos.logo}
                alt="Logo"
                className="h-auto w-40"
                loading="lazy"
              />
            </a>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="/" className="text-black hover:text-primary">
              {t.nav.home}
            </a>
            <a
              href="#services"
              onClick={(e) => handleSectionClick(e, "services")}
              className="text-black hover:text-primary"
            >
              {t.nav.services}
            </a>
            <a
              href="#about"
              onClick={(e) => handleSectionClick(e, "about")}
              className="text-black hover:text-primary"
            >
              {t.nav.about}
            </a>
            <a href="/fleet" className="text-black hover:text-primary">
              {t.nav.fleet}
            </a>
            <a
              href="#contact"
              onClick={(e) => handleSectionClick(e, "contact")}
              className="text-black hover:text-primary"
            >
              {t.nav.contact}
            </a>

            <div className="relative" ref={langMenuRef}>
              <button
                onClick={() => setIsLangMenuOpen(!isLangMenuOpen)}
                className="flex items-center space-x-1 text-black hover:text-primary p-2 rounded-md hover:bg-gray-100"
              >
                <Globe size={20} />
              </button>
              {isLangMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <button
                      onClick={() => handleLanguageChange("ro")}
                      className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                    >
                      Română
                    </button>
                    <button
                      onClick={() => handleLanguageChange("en")}
                      className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                    >
                      English
                    </button>
                    <button
                      onClick={() => handleLanguageChange("de")}
                      className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                    >
                      Deutsch
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)} className="text-black">
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="/"
              className="block px-3 py-2 text-black hover:text-primary"
            >
              {t.nav.home}
            </a>
            <a
              href="#services"
              onClick={(e) => handleSectionClick(e, "services")}
              className="block px-3 py-2 text-black hover:text-primary"
            >
              {t.nav.services}
            </a>
            <a
              href="#about"
              onClick={(e) => handleSectionClick(e, "about")}
              className="block px-3 py-2 text-black hover:text-primary"
            >
              {t.nav.about}
            </a>
            <a
              href="/fleet"
              className="block px-3 py-2 text-black hover:text-primary"
            >
              Fleet
            </a>
            <a
              href="#contact"
              onClick={(e) => handleSectionClick(e, "contact")}
              className="block px-3 py-2 text-black hover:text-primary"
            >
              {t.nav.contact}
            </a>
            <div className="px-3 py-2 space-y-1">
              <button
                onClick={() => handleLanguageChange("ro")}
                className="block text-black hover:text-primary w-full text-left px-2 py-1 rounded hover:bg-gray-100"
              >
                Română
              </button>
              <button
                onClick={() => handleLanguageChange("en")}
                className="block text-black hover:text-primary w-full text-left px-2 py-1 rounded hover:bg-gray-100"
              >
                English
              </button>
              <button
                onClick={() => handleLanguageChange("de")}
                className="block text-black hover:text-primary w-full text-left px-2 py-1 rounded hover:bg-gray-100"
              >
                Deutsch
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
